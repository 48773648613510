import { Controller } from '@hotwired/stimulus';

import { publish, events } from 'lib/event_bus';

/**
 * remove controller: remove the current element, or mark it for destruction.
 */
export default class extends Controller {
  static targets = ['destroyField'];

  static values = { confirmation: String };

  /**
   * Mark the record for destruction by setting the destroyField value to a true
   * value, and hide the element.
   *
   * Publishes the 'destroyed' event on the element.
   */
  destroy() {
    // eslint-disable-next-line no-alert
    if (this.hasConfirmationValue && !window.confirm(this.confirmationValue))
      return;

    this.destroyFieldTarget.value = '1';
    this.element.classList.add('collapse');

    publish(events.DESTROYED, {}, { on: this.element });
  }

  /**
   * Remove the current element.
   *
   * Publishes the 'removed' event on the element's parent.
   *
   * Example usage:
   * - attach to a row with a remote delete link and specify an action like
   *    "ajax:success->remove#remove"
   * - attach to a temporary element and specify an action like
   *    "turbolinks:before-cache@document->remove#remove".
   */
  remove() {
    // eslint-disable-next-line no-alert
    if (this.hasConfirmationValue && !window.confirm(this.confirmationValue))
      return;

    const parent = this.element.parentNode;
    this.element.remove();

    publish(events.REMOVED, { removed: this.element }, { on: parent });
  }
}
