import StripeController from '../stripe/stripe_controller';

/**
 * payments--payment-selection controller: stripe integration for paying with existing source
 */

export default class extends StripeController {
  static targets = [
    'cvcContainer',
    'newSourceContainer',
    'newSourceField',
    'paymentSource',
    'stripeCvcTokenField',
  ];

  connect() {
    super.connect();
    this.initializeStripeElements();
  }

  initializeStripeElements() {
    // Configure credit card inputs, which are created and controlled by Stripe
    if (this.hasCvcContainerTarget) {
      const stripeElements = this.stripe.elements();
      this.cardCvc = stripeElements.create('cardCvc');

      this.cardCvc.mount(this.cvcContainerTarget);
    }

    this.element.classList.add('show');
  }

  onChangePaymentSource(event) {
    const { value } = event.target;
    const newSource = value === 'new_source';

    // Show or hide the new source container
    this.newSourceContainerTarget.classList.toggle('show', newSource);

    // Ensure only the selected input's containing card has the card-selected class
    this.paymentSourceTargets.forEach((element) => {
      element
        .closest('.card')
        .classList.toggle('card-selected', element.value === value);
    });
  }

  /**
   * Returns true if the new credit card source option is selected or if there's
   * no new source field target (which happens when there are no existing
   * payment sources to select from).
   */
  get newSourceSelected() {
    return !this.hasNewSourceFieldTarget || this.newSourceFieldTarget.checked;
  }

  /**
   * Returns true if the existing credit card source option is selected.
   */
  get existingSourceSelected() {
    return (
      !this.hasPaymentSourceTarget ||
      this.paymentSourceTargets.some((target) => {
        return target.checked;
      })
    );
  }

  createToken() {
    super.toggleFieldsDisabled(true);
    super.toggleError(false);

    // Use Stripe.js to create a token. We only need to pass in one Element
    // from the Element group in order to create a token.
    return this.stripe.createToken('cvc_update', this.cardCvc).then(
      (result) => {
        if (result.error) {
          super.toggleError(result.error.message || 'An error occurred.');
          super.toggleFieldsDisabled(false);
          return Promise.reject();
        }

        // Store the stripe token in a hidden form field.  The containing form
        // should now be submitted.
        this.stripeCvcTokenFieldTarget.value = result.token.id;
        return Promise.resolve();
      },
      () => {
        super.toggleError('An error occurred.');
        super.toggleFieldsDisabled(false);
        return Promise.reject();
      },
    );
  }
}
